<template>
  <div class="page-adminUserManage">
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="登录账号">
              <a-input v-decorator="['adminLoginname']" placeholder="请输入登录账号"></a-input>
            </a-form-item>
            <a-form-item label="用户名">
              <a-input v-decorator="['nickname']" placeholder="请输入用户名"></a-input>
            </a-form-item>
            <a-form-item label="启用状态">
              <a-select class="form-control sm" v-decorator="['state']" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in typesMap.state" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :expandIconColumnIndex="1"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="登录账号">
          <a-input
            v-decorator="[
              'adminLoginname',
              {
                rules: [{ required: true, message: '请输入登录账号' }],
              },
            ]"
            placeholder="请输入登录账号"
          ></a-input>
        </a-form-item>
        <a-form-item label="用户名">
          <a-input
            v-decorator="[
              'nickname',
              {
                rules: [{ required: true, message: '请输入用户名' }],
              },
            ]"
            placeholder="请输入用户名"
          ></a-input>
        </a-form-item>

        <a-form-item label="管理后台角色">
          <a-select
            mode="multiple"
            v-decorator="['roleId']"
            :options="roleOptions"
            placeholder="请选择角色"
            allowClear
          ></a-select>
        </a-form-item>
        <a-form-item label="统一门户角色">
          <a-select
            mode="multiple"
            v-decorator="[
              'roleIdUn',
              {
                rules: [{ required: false, message: '请选择统一门户角色' }],
              },
            ]"
            :options="roleOptionsUn"
            placeholder="请选择角色"
            allowClear
          ></a-select>
        </a-form-item>
        <a-form-item label="密码" v-if="!modal.data.id">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入密码' }],
              },
            ]"
            type="password"
            placeholder="请输入密码"
          ></a-input>
        </a-form-item>
        <a-form-item label="新密码" v-if="modal.data.id">
          <a-input v-decorator="['password']" type="password" placeholder="请输入新密码"></a-input>
        </a-form-item>
        <a-form-item hidden></a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '登录账号',
        width: '15%',
        align: 'center',
        dataIndex: 'adminLoginname',
      },
      {
        title: '用户名',
        width: '15%',
        align: 'center',
        dataIndex: 'nickname',
      },
      {
        title: '角色',
        align: 'center',
        customRender: (text, row, index) => {
          return <div>{this.renderRoles(row.roles)}</div>
        },
      },
      {
        title: '启用状态',
        width: '15%',
        align: 'center',
        dataIndex: 'state',
        customRender: (text, row, index) => {
          const checked = text
          // 内置角色不可停用删除
          const canDelOrDisable = !row.built
          return (
            <a-switch
              disabled={!canDelOrDisable}
              loading={row.switchLoading}
              size="small"
              checked={checked}
              onChange={() => {
                this.changeState(row, !checked, index)
              }}
            />
          )
        },
      },
      {
        title: '操作',
        width: '15%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          const canUpdate = row.state
          return (
            <div>
              {canUpdate && (
                <span>
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.edit(row)
                    }}
                  >
                    修改
                  </a>
                </span>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
      roleOptions: [],
      roleOptionsUn: [],
    }
  },
  computed: {
    typesMap() {
      return {
        state: [
          { value: 1, label: '启用' },
          { value: 0, label: '停用' },
        ],
        type: [
          { value: 'web', label: '管理后台' },
          // { value: 'app', label: 'APP' },
          { value: 'uni', label: '统一门户' },
        ],
      }
    },
  },

  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/ums/admin/listPage', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const list = res.data.data
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {}
      this.loading = false
    },
    add() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增'
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '修改'
      this.modal.data = row
      const roleId = []
      const roleIdUn = []
      row.roles.map((role) => {
        if (role.adminType === '0') {
          if (!roleId.includes(role.roleId)) {
            roleId.push(role.roleId)
          }
        }
        if (role.adminType === '1') {
          if (!roleIdUn.includes(role.roleId)) {
            roleIdUn.push(role.roleId)
          }
        }
      })
      this.safeSetFieldsValue(this.modal.form, {
        ...row,
        roleId,
        roleIdUn,
      })
    },
    async remove(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确定删除记录?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },
    async changeState(row, state, index) {
      const bizId = 'adminId'
      this.$set(this.dataSource[index], 'switchLoading', true)
      try {
        await this.$axios.post('/ums/admin/updateJson', {
          [bizId]: row[bizId],
          state,
        })
        this.doQuery()
        this.$message.success('操作成功!')
      } catch (e) {}
      this.$set(this.dataSource[index], 'switchLoading', false)
    },
    renderRoles(roles) {
      const adminRoles = []
      const uniRoles = []
      roles.forEach((role) => {
        if (role.adminType === '0') {
          if (!adminRoles.some((item) => item.roleId === role.roleId)) {
            adminRoles.push(role)
          }
        }
        if (role.adminType === '1') {
          if (!uniRoles.some((item) => item.roleId === role.roleId)) {
            uniRoles.push(role)
          }
        }
      })
      return (
        <div>
          <div class="flex justify-center pb-1">
            <div>系统:</div>
            <div class="flex-1 text-left">
              {adminRoles.map((r) => {
                return <a-tag class="mb-1 ml-1">{r.roleName}</a-tag>
              })}
            </div>
          </div>
          <div class="flex">
            <div>门户:</div>
            <div class="flex-1 text-left">
              {uniRoles.map((r) => {
                return <a-tag class="mb-1 ml-1">{r.roleName}</a-tag>
              })}
            </div>
          </div>
        </div>
      )
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const actionUrl = this.modal.data.id ? '/ums/admin/updateJson' : '/ums/admin/saveJson'
            const bizId = 'adminId'
            await this.$axios.post(
              actionUrl,
              {
                [bizId]: this.modal.data[bizId],
                ...params,
              },
              {
                postDataType: 'json',
              }
            )
            this.$message.success('操作成功!')
            this.resetModal()
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async getRoleOptions(type) {
      // 只管理后台用户
      const res = await this.$axios.post('/ums/role/listPage', {
        pageSize: 999,
        pageNo: 1,
        state: 1,
        type,
      })
      const list = res.data.data.map((item) => {
        return {
          value: item.roleId,
          label: item.roleName,
        }
      })
      return list
    },
    async loadRoleOptions() {
      const roleOptions = await this.getRoleOptions()
      const roleOptionsUn = await this.getRoleOptions('uni')
      this.roleOptions = roleOptions
      this.roleOptionsUn = roleOptionsUn
    },
  },
  mounted() {
    this.doQuery()
    this.loadRoleOptions()
  },
}
</script>

<style lang="less" scoped></style>
